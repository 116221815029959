<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 17.5 17.5">
    <path
      :style="iconStyle"
      d="M16.953-12.422a1.808,1.808,0,0,1,.547,1.328V-.625A1.808,1.808,0,0,1,16.953.7a1.808,1.808,0,0,1-1.328.547H1.875A1.808,1.808,0,0,1,.547.7,1.808,1.808,0,0,1,0-.625v-13.75A1.808,1.808,0,0,1,.547-15.7a1.808,1.808,0,0,1,1.328-.547H12.344a1.808,1.808,0,0,1,1.328.547Zm-6.328-1.953h-5v3.125h5ZM15.391-.625a.207.207,0,0,0,.234-.234V-11.016a.225.225,0,0,0-.078-.156L12.5-14.219v3.906a.9.9,0,0,1-.273.664.9.9,0,0,1-.664.273H4.688a.9.9,0,0,1-.664-.273.9.9,0,0,1-.273-.664v-4.062H2.109a.207.207,0,0,0-.234.234V-.859a.207.207,0,0,0,.234.234Zm-9.062-6.8A3.306,3.306,0,0,1,8.75-8.437a3.306,3.306,0,0,1,2.422,1.016A3.306,3.306,0,0,1,12.188-5a3.306,3.306,0,0,1-1.016,2.422A3.306,3.306,0,0,1,8.75-1.562,3.306,3.306,0,0,1,6.328-2.578,3.306,3.306,0,0,1,5.313-5,3.306,3.306,0,0,1,6.328-7.422ZM7.637-3.887a1.512,1.512,0,0,0,1.113.449,1.512,1.512,0,0,0,1.113-.449A1.512,1.512,0,0,0,10.313-5a1.512,1.512,0,0,0-.449-1.113A1.512,1.512,0,0,0,8.75-6.562a1.512,1.512,0,0,0-1.113.449A1.512,1.512,0,0,0,7.188-5,1.512,1.512,0,0,0,7.637-3.887Z"
      transform="translate(0 16.25)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '17.5',
    },
    width: {
      type: String,
      default: '17.5',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
